import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { IoIosClose, IoIosArrowBack } from 'react-icons/io';
import { IoClose, IoTriangle } from "react-icons/io5";
import { Modal } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import { createClaim, getAllClaim } from '../../../store/actions/allClaim';
import Loader from '../../../components/Loader/Loader';
import img1 from '../../../images/damage/1-05.png';
import instruction1 from '../../../images/instructions/Web_instructions-03.png';
import instruction2 from '../../../images/instructions/Web_instructions-01.png';
import fallback from '../../../../src/images/fallimageh.png';
import axios from '../../../store/axios'
import {
  addImage,
  createRoom,
  getAllRoom,
  getImageInformation,
} from '../../../store/actions/allRoom';
import { usaStates } from './Layouts/AddressForm/fiftyStates';
import { setCreditsBackend } from '../../../store/actions/credits';
import PrimaryButton from '../../../components/Buttons/PrimaryButton/PrimaryButton';
import { isObjEmpty } from '../../../utils/utils';
import NextIcon from '../../../assets/icons/nextIcon.png';
import ImageAdd from '../NewRoom/ImageAdd/ImageAdd';
import HostaAILogo from "../../../images/HostaAILogo.svg";
import "../NewRoom/NewRoom.css";
import Tip1 from '../../../assets/images/tip1.png';
import Tip2Wrong from '../../../assets/images/tip2Wrong.svg';
import Tip2Right from '../../../assets/images/tip2Right.png';
import Tip3Wrong from '../../../assets/images/tip3Wrong.svg';
import Tip3Right from '../../../assets/images/tip3Right.svg';
import Tip4 from '../../../assets/images/tip4.svg';
import TipCross from '../../../assets/images/tipCross.svg';
import TipTick from '../../../assets/images/tipTick.svg';
import UserType from '../RoomInterface/Layouts/UserType/UserType';


const NewClaim = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const initialErrorData = {
    address: '',
    damageType: '',
    customerID: "",
    addressLine1: '',
    city: '',
    cityCode: '',
    zip: '',
  };

  const credits = useSelector(state => state.credit.credits);
  const [showRules, setShowRules] = useState(true);
  const [address, setAddress] = useState('');
  const [apt, setApt] = useState();
  const [damageType, setDamageType] = useState('');
  const [error, setError] = useState(initialErrorData);
  const [addressDetails, setAddressDetails] = useState({});
  const [modalShown, setModalShown] = useState(false);
  const [show, setShow] = useState(false);
  const [claimFields, setClaimFields] = useState({});
  const [oneImage, setoneImage] = useState(false);
  const [count, setCount] = useState(0);
  const [instruction, setInstruction] = useState(false);
  const [maxVal] = useState('8');
  const [maxDamageImages] = useState('1');
  const [maxOppositeImages] = useState('4');
  const [maxOppositeImages1] = useState('1');
  const [damageImages, setDamageImages] = useState([]);
  const [oppsiteImages, setOppositeImages] = useState(Array(Number(maxOppositeImages)).fill(null));
  const [oppsiteImages1, setOppositeImages1] = useState([]);
  const [files, setFiles] = useState(Array(2).fill(null));
  const [loading, setLoading] = useState(false);
  const [customerID, setCustomerID] = useState("");
  const [info, setInfo] = useState(false);
  const [claimtempid, setClaimtempid] = useState("");
  const [claimroomid, setClaimroomid] = useState("");
  const [overlayimagessubbmited, setOverlayimagessubbmited] = useState(false);
  const [imagessubbmited, setImagessubbmited] = useState(false);
  const [oppsiteImagesError, setOppositeImagesError] = useState([]);
  const [oppsiteImagesUploading, setOppositeImagesUploading] = useState(false);
  const [filesError, setFilesError] = useState([]);
  const [filesUploading, setFilesUploading] = useState(false);
  const [showManualAddressForm, changeAutoManualAddressFormView] = useState(false);
  //ManualEntry address values ManualEntry address values ManualEntry address values 
  //ManualEntry address values ManualEntry address values ManualEntry address values 
  const [address1_manual, setAddress1_manual] = useState('');
  const [address2, setAddress2] = useState('');
  const [city_manual, setCity_manual] = useState('');
  const [usState_manual, setUSstate_manual] = useState('');
  const [zip_manual, setZip_manual] = useState('');
  const [customerID_manual, setCustomerID_manual] = useState("");
  const [open, setOpen] = useState(false);

  //This flag updateReturnedFromNewUI  calls or prevents createRoomId from firing in NewClaim.  
  //It is needed ONLY in newClaim.js because out of the three routes ADD ROOM in NewClaim is the 
  //ONLY one that starts in itself then returns to itself after the newUI, so we need  to set this 
  //context flag from false to true when we click the "ADD ROOM BUTTON" here in new claim so, page onload 
  //second time, when we return from new UI returnedFromNewUI  to be set to true  so that UseEffect creates
  //createRoomId w linkParameter  
  const ssReturnedFromNewUI = sessionStorage.returnedFromNewUI;
  const ssPreservedTempClaimId = sessionStorage.preservedTempClaimId;
  const roomType = sessionStorage.roomType;
  const currentRoomId = sessionStorage.ssRoomId;
  const emptyRoomId = currentRoomId === undefined ? true : false;
  useEffect(() => {
    if (ssReturnedFromNewUI && emptyRoomId) {
      console.log('Were CREATEING ONE!!!');
      const getRoomId = async () => {
        const roomId = await createRoom(ssPreservedTempClaimId, { roomType });
        sessionStorage.ssRoomId = roomId;
        setClaimtempid(ssPreservedTempClaimId);
        setClaimroomid(roomId);
        console.log('NEWROOM-roomId:', roomId);
      };
      getRoomId().catch(console.error);
    }
    if (!emptyRoomId) {
      setClaimtempid(ssPreservedTempClaimId);
      setClaimroomid(currentRoomId);
    }
  }, []);

  useEffect(() => {
    const arrLength = damageImages.length;
    if (arrLength > maxDamageImages) {
      damageImages.splice(1, arrLength - maxDamageImages);
      setError({ ...error, damageImages: 'You can upload only 1 image' });
    } else {
      setError({ ...error, damageImages: '' });
    }
  }, [damageImages, maxDamageImages]);

  useEffect(() => {
    const arrLength = damageImages.length;
    if (arrLength > maxDamageImages) {
      damageImages.splice(1, arrLength - maxDamageImages);
      setError({ ...error, damageImages: 'You can upload only 1 image' });
    } else {
      setError({ ...error, damageImages: '' });
    }
  }, [damageImages, maxDamageImages]);

  useEffect(() => {
    const arrLength = oppsiteImages.length;
    if (arrLength > maxOppositeImages) {
      oppsiteImages.splice(maxOppositeImages, arrLength - maxOppositeImages);
      setError({ ...error, oppsiteImages: 'You can upload upto 2 images' });
    } else {
      setError({ ...error, oppsiteImages: '' });
    }
  }, [oppsiteImages, maxOppositeImages]);

  useEffect(() => {
    const arrLength = oppsiteImages1.length;
    if (arrLength > maxOppositeImages1) {
      oppsiteImages1.splice(maxOppositeImages1, arrLength - maxOppositeImages1);
      setError({ ...error, oppsiteImages1: 'You can upload upto 2 images' });
    } else {
      setError({ ...error, oppsiteImages1: '' });
    }
  }, [oppsiteImages1, maxOppositeImages1]);

  useEffect(() => {
    const arrLength = files.length;
    if (arrLength > maxVal) {
      files.splice(8, arrLength - maxVal);
      setError({ ...error, files: 'You can upload upto 8 images' });
    } else {
      setError({ ...error, files: '' });
    }
  }, [files, maxVal]);







  //  AUTOCOMPLETE ADDRESS FORM CHECKADDRESS AND HANDLEGOOGLEADDRESS WHICH IS CALLED  ONCHANGE IN  UI GooglePlacesAutocomplete
  //  AUTOCOMPLETE ADDRESS FORM CHECKADDRESS AND HANDLEGOOGLEADDRESS WHICH IS CALLED ONCHANGE IN  UI GooglePlacesAutocomplete
  //  AUTOCOMPLETE ADDRESS FORM CHECKADDRESS AND HANDLEGOOGLEADDRESS WHICH IS CALLED ONCHANGE IN  UI GooglePlacesAutocomplete
  const checkAdress = (addrDetails, addr) => {
    let address1 = addr.label;
    let city = '';
    let state = '';
    let zip = '';
    let country = '';

    if (addrDetails.length > 0) {
      console.log('ADDRESSDEETS', addrDetails);
      addrDetails[0].address_components.map(item => {
        if (item.types[0] === 'locality') {
          city = item.long_name;
        }
        if (item.types[2] === 'sublocality_level_1') {
          city = item.long_name;
        }
        if (
          item.types[0] === 'administrative_area_level_1' ||
          item.types[0] === 'administrative_area_level_2' ||
          item.types[0] === 'administrative_area_level_3'
        ) {
          state = item.short_name;
        }
        if (item.types[0] === 'postal_code') {
          zip = item.short_name;
        }
        else {
          zip = " ";
        }
        if (item.types[0] === 'street_number') {
          address1 = `${item.short_name} `;
        }
        if (item.types[0] === 'route') {
          address1 += item.long_name;
        }
        if (item.types[0] === 'country') {
          country = item.long_name;
        }
      });
    }
    const errorTemplate = {};
    if (!state || !city || !zip || !country) {
      errorTemplate.address = 'Address is not valid';
    }
    if (address1.trim().length === 0) {
      errorTemplate.address = 'Address Cannot be empty';
    }
    setError(errorTemplate);
  };


  const handleGoogleAddress = result => {
    setAddress(result.label);
  };



  //AUTOCOMPLETE ADDRESS FORM ASSIGN ADDRESS VALUES AND (CLAIM CREATION OR IMAGEREQUEST) 
  //AUTOCOMPLETE ADDRESS FORM ASSIGN ADDRESS VALUES AND (CLAIM CREATION OR IMAGEREQUEST)
  //AUTOCOMPLETE ADDRESS FORM ASSIGN ADDRESS VALUES AND (CLAIM CREATION OR IMAGEREQUEST)
  //AUTOCOMPLETE ADDRESS FORM ASSIGN ADDRESS VALUES AND (CLAIM CREATION OR IMAGEREQUEST) 
  const handleImgReq = () => {
    reqImg = true;
    handleAddRoom();
  };

  const handleAddRoom = () => {
    let address1 = address;
    let city = '';
    let state = '';
    let zip = '';
    let country = '';
    if (addressDetails.length > 0) {
      addressDetails[0].address_components.map(item => {
        if (item.types[0] === 'locality') {
          city = item.long_name;
        }
        else if (item.types[2] === 'sublocality_level_1') {
          city = item.long_name;
        }
        if (
          item.types[0] === 'administrative_area_level_1' ||
          item.types[0] === 'administrative_area_level_2' ||
          item.types[0] === 'administrative_area_level_3'
        ) {
          state = item.short_name;
        }
        if (item.types[0] === 'postal_code') {
          zip = item.short_name;
        }
        else {
          zip = " ";
        }
        if (item.types[0] === 'street_number') {
          address1 = `${item.short_name} `;
        }
        if (item.types[0] === 'route') {
          address1 += item.long_name;
        }
        if (item.types[0] === 'country') {
          country = item.long_name;
        }
      });
    }
    const errorTemplate = {};
    if (!state || !city || !zip || !country) {
      errorTemplate.address = 'Address is not valid';
    }
    if (address1.trim().length === 0) {
      errorTemplate.address = 'Address Cannot be empty';
    }
    if (customerID == '' || !Boolean(customerID)) {
      errorTemplate.customerID = 'Claim ID is Required.'
    }
    setError(errorTemplate);
    if (isObjEmpty(errorTemplate)) {
      setError({});
      const data = {
        damageType,
        address1,
        address2: '',
        city,
        state,
        zip,
        country,
        notes: '',
        customerID
      };
      if (claimroomid == "") {
        handleFormSubmit(data, customerID);
      }
      if (!reqImg) {
        setShow(true);
      }
      setClaimFields(data);
    }
  };

  const handleFormSubmit = async (data, customerID) => {
    setLoading(true);
    let myId = '';
    const claimData = await dispatch(createClaim(data || claimFields, customerID)).then((data) => myId = data);
    if (reqImg) {
      setModalShown(true);
      setLoading(false);
      setTimeout(() => setOpen(claimData), 1500);
    } else {
      setLoading(false);
      //handleAddClaimAndRoom(claimData.claimId);
      redirectToCapture(claimData.claimId);
    }
  };

  //updateReturnURLPath persists url for return from new ui back to old
  //updateOneImageFromContext persists tf flag for modal display
  //to show proper image upload modal on return from old ui
  //updateOneImageFromContext(true) will suppress address entry modal
  //updateOneImageFromContext(true) forces Count=== uploadImages modals to display Line1167
  // claimTempId set here, When we return from new UI,to
  // count===0 (imgUpload), local state will reload, and we can access 
  // preservedClaimTempId in sessionState, available for HandleroomId. 
  const handleAddClaimAndRoom = claimId => {
    let err = false;
    setLoading(true);
    sessionStorage.preservedTempClaimId = claimId;
    sessionStorage.returnedFromNewUI = true;
    sessionStorage.returnUrlPath = `/app/newclaim`;
    setLoading(false);
    history.push(`/startme/param`);
    //FLY AWAY TO NEW UI FLY AWAY TO NEW UI  FLY AWAY TO NEW UI FLY AWAY TO NEW UI 
    //FLY AWAY TO NEW UI FLY AWAY TO NEW UI  FLY AWAY TO NEW UI FLY AWAY TO NEW UI 
    //FLY AWAY TO NEW UI FLY AWAY TO NEW UI  FLY AWAY TO NEW UI FLY AWAY TO NEW UI 
  };


  const redirectToCapture = claimId => {
    axios({
      method: 'POST',
      url: `/claim/${claimId}/room/request?returnLink=True&expirationHours=2160`,
      data: {
        // claimInfo: data,
        // customerID: customerID
      },
    }).then(res => {
      console.log(res.data.link)
      window.location.href = res.data.link + '/' + claimId
    })
  }









  //MANUAL ADDRESS-REQ IMAGE BUTTON REQ IMAGE BUTTON REQ IMAGE BUTTON REQ IMAGE BUTTON 
  //MANUAL ADDRESS-REQ IMAGE BUTTON REQ IMAGE BUTTON REQ IMAGE BUTTON REQ IMAGE BUTTON 
  //MANUAL ADDRESS-REQ IMAGE BUTTON REQ IMAGE BUTTON REQ IMAGE BUTTON REQ IMAGE BUTTON 
  //MANUAL ADDRESS-REQ IMAGE BUTTON REQ IMAGE BUTTON REQ IMAGE BUTTON REQ IMAGE BUTTON 
  const manualAddressHandleImageRequest = () => {
    reqImg = true;
    manualAddressHandleChecks();
  };

  //MANUAL ADDRESS-ADD ROOM BUTTON HANDLE ADDRESS ERRORS AND (CLAIM CREATION OR IMAGEREQUEST) 
  //MANUAL ADDRESS-ADD ROOM BUTTON HANDLE ADDRESS ERRORS AND (CLAIM CREATION OR IMAGEREQUEST) 
  //MANUAL ADDRESS-ADD ROOM BUTTON HANDLE ADDRESS ERRORS AND (CLAIM CREATION OR IMAGEREQUEST) 
  //MANUAL ADDRESS-ADD ROOM BUTTON HANDLE ADDRESS ERRORS AND (CLAIM CREATION OR IMAGEREQUEST) 
  // >>>NEED INPUT FIELDS FORM
  // >>>NEED USESTATE FIELDS FOR ADDRESSES
  // >>>NEED STATE  VARIABLES
  //NEED ALL CLASSNAMES AND USESTATE VARIABLES USED ANYWHERE 
  const manualAddressHandleChecks = async () => {
    const errorTemplate = {};
    if (address1_manual.trim().length === 0) {
      errorTemplate.addressLine1 = 'Address Cannot be empty';
      setError(errorTemplate);
    }
    if (customerID_manual == '' || !Boolean(customerID_manual)) {
      errorTemplate.customerID = 'Claim ID is Required.'
    }
    if (city_manual.trim().length === 0) {
      errorTemplate.city = 'City cannot be empty';
      setError(errorTemplate);
    }
    if (!usState_manual) {
      errorTemplate.cityCode = 'State cannot be empty';
      setError(errorTemplate);
    }
    if (zip_manual.trim().length === 0) {
      errorTemplate.zip = 'Zip cannot be empty';
      setError(errorTemplate);
    }
    if (zip_manual.trim().length < 5 || zip_manual.trim().length > 5) {
      errorTemplate.zip = 'Zip should be  5 digits';
      setError(errorTemplate);
    } else if (isObjEmpty(errorTemplate)) {
      setError(initialErrorData);
      if (isObjEmpty(errorTemplate)) {
        setError({});
        if (reqImg) {
          if (claimroomid == "") {
            await manualAddressHandleFormSubmit();
          }
        }
        else {
          if (claimroomid == "") {
            await manualAddressHandleFormSubmit();
          }
          setShow(true);
          const data = {
            damageType,
            address1: address1_manual,
            address2: '',
            city: city_manual,
            state: usState_manual,
            zip: zip_manual,
            country: 'USA',
            notes: '',
            customerID: customerID_manual,
            handleaddressform: "address"
          };
          setClaimFields(data);
        }
      }
    }
  };

  const manualAddressHandleFormSubmit = async () => {
    setLoading(true);
    const data = {
      damageType,
      address1: address1_manual,
      address2,
      city: city_manual,
      state: usState_manual,
      zip: zip_manual,
      country: 'USA',
      notes: '',
      customerID: customerID_manual,
      handleaddressform: "address"
    };
    const claimData = await dispatch(createClaim(data, customerID_manual));
    if (reqImg) {
      setModalShown(true);
      setLoading(false);
      setTimeout(() => setOpen(claimData), 1500);
    } else {
      // setLoading(false);
      // setClaimtempid(claimData.claimId);
      // setLoading(true);
      sessionStorage.preservedTempClaimId = claimData.claimId;
      sessionStorage.returnedFromNewUI = true;
      sessionStorage.returnUrlPath = `/app/newclaim`;
      // setLoading(false);
      //history.push(`/startme/param`);
      redirectToCapture(claimData.claimId);
    }
  };
  //END MANUAL END REQ IMAGE BUTTON ADD ROOM BUTTON END END REQ IMAGE BUTTON ADD ROOM BUTTON END END REQ IMAGE BUTTON ADD ROOM BUTTON
  //END END REQ IMAGE BUTTON ADD ROOM BUTTON END END REQ IMAGE BUTTON ADD ROOM BUTTON END END REQ IMAGE BUTTON ADD ROOM BUTTON
  //END END REQ IMAGE BUTTON ADD ROOM BUTTON END END REQ IMAGE BUTTON ADD ROOM BUTTON END END REQ IMAGE BUTTON ADD ROOM BUTTON









  //LOOKS GOOD BUTTON ONCLICK!!! //LOOKS GOOD BUTTON ONCLICK!!! //LOOKS GOOD BUTTON ONCLICK!!!
  //LOOKS GOOD BUTTON ONCLICK!!! //LOOKS GOOD BUTTON ONCLICK!!! //LOOKS GOOD BUTTON ONCLICK!!!
  //LOOKS GOOD BUTTON ONCLICK!!! //LOOKS GOOD BUTTON ONCLICK!!! //LOOKS GOOD BUTTON ONCLICK!!!
  //LOOKS GOOD BUTTON ONCLICK!!! //LOOKS GOOD BUTTON ONCLICK!!! //LOOKS GOOD BUTTON ONCLICK!!!
  //LOOKS GOOD BUTTON ONCLICK!!! //LOOKS GOOD BUTTON ONCLICK!!! //LOOKS GOOD BUTTON ONCLICK!!!
  //LOOKS GOOD BUTTON ONCLICK!!! //LOOKS GOOD BUTTON ONCLICK!!! //LOOKS GOOD BUTTON ONCLICK!!!
  const handleRoomId = async () => {
  };







  //START OF Image processing and handle hide NAV METHODS START OF Image processing and handle hide METHODS
  //START OF Image processing and handle hide NAV METHODS START OF Image processing and handle hide METHODS
  //START OF Image processing and handle hide NAV METHODS START OF Image processing and handle hide METHODS
  //START OF Image processing and handle hide NAV METHODS START OF Image processing and handle hide METHODS
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxFiles: maxVal,
    onDrop: acceptedFiles => {
      const ids = acceptedFiles.map(() => uuidv4());
      const newFiles = acceptedFiles.map((file, i) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          id: ids[i],
        })
      );

      if (count === 0) setOppositeImages([...oppsiteImages, ...newFiles]);
      else if (count === 1) setOppositeImages1([...oppsiteImages1, ...newFiles]);
      else if (count === 2) setFiles([...files, ...newFiles]);
      else if (count === 3) setDamageImages([...damageImages, ...newFiles]);
    },
  });

  const handleOverlayFirst = () => {
    handleAddClaimAndRoomoverviewImages();
  };

  const handleAddClaimAndRoomoverviewImages = async () => {
    let err = false;
    let oppositeErrors = []
    setOppositeImagesUploading(true);

    for (const file of [...oppsiteImages, ...oppsiteImages1]) {
      const indexOf = [...oppsiteImages, ...oppsiteImages1].indexOf(file);
      const imageNumber = (indexOf + 1).toString();
      const ext = file.name.split('.').pop();
      const imgInfo = await dispatch(getImageInformation(claimtempid, claimroomid, ext, 'overviewImages', imageNumber));
      if (imgInfo === undefined) {
        oppositeErrors.push(true);
        err = true;
      } else {
        const data = makeFormData(imgInfo.uploadFields, file);
        oppositeErrors.push(false);
        dispatch(addImage(imgInfo.uploadLink, data));
      }
    }

    setOppositeImagesError(oppositeErrors);
    if (err !== true) {
      setCount(count + 1);
      setShow(false);
      setOppositeImagesUploading(false);
    } else {
      setOppositeImagesUploading(false);
      alert('ONE Image type is not valid');
    }
  };


  const handleImagesFirst = async data => {
    handleAddClaimAndRoomImages();
  };

  const handleAddClaimAndRoomImages = async () => {
    let err = false;
    let filesError = [];
    setFilesUploading(true);

    for (const file of files) {
      if (file !== null) {

        const ext = file.name.split('.').pop();
        const imgInfo = await dispatch(getImageInformation(claimtempid, claimroomid, ext, 'images'));
        if (imgInfo === undefined) {
          // setLoading(false);
          filesError.push(true)
          err = true;
        } else {
          const data = makeFormData(imgInfo.uploadFields, file);
          filesError.push(false)
          dispatch(addImage(imgInfo.uploadLink, data));
        }
      }
    }

    setFilesError(filesError)
    if (err !== true) {
      dispatch(setCreditsBackend(claimtempid, claimroomid));
      dispatch(getAllRoom(claimtempid, { start: 0, end: 9 }));
      setFilesUploading(false);
      history.push(`/app/roomaddedpopup/${claimtempid}`);
    } else {
      setFilesUploading(false);
      alert('Image type is not valid');
    }
  };


  const makeFormData = (uploadFields, file) => {
    const data = new FormData();
    data.append('key', uploadFields.key);
    data.append('x-amz-algorithm', uploadFields['x-amz-algorithm']);
    data.append('x-amz-credential', uploadFields['x-amz-credential']);
    data.append('x-amz-date', uploadFields['x-amz-date']);
    data.append('x-amz-security-token', uploadFields['x-amz-security-token']);
    data.append('policy', uploadFields.policy);
    data.append('x-amz-signature', uploadFields['x-amz-signature']);
    data.append('x-amz-meta-claimId', uploadFields.claimId);
    data.append('x-amz-meta-roomId', uploadFields.roomId);
    data.append('x-amz-meta-submitter', uploadFields.submitter);
    data.append('x-amz-meta-imageField', uploadFields.imageField);
    if (uploadFields.imageNumber) {
      data.append('x-amz-meta-imageNumber', uploadFields.imageNumber);
    };
    data.append('file', file);
    return data;
  };

  const handleImgDelete = e => {
    if (count === 0) {
      oppsiteImages.map(() => {
        const filteredFiles = oppsiteImages.filter(item => e !== item.id);
        setOppositeImages(filteredFiles);
        return true;
      });
    } else if (count === 1) {
      oppsiteImages1.map(() => {
        const filteredFiles = oppsiteImages1.filter(item => e !== item.id);
        setOppositeImages1(filteredFiles);
        return true;
      });
    } else if (count === 2) {
      files.map(() => {
        const filteredFiles = files.filter(item => e !== item.id);
        setFiles(filteredFiles);
        return true;
      });
    } else if (count === 3) {
      damageImages.map(() => {
        const filteredFiles = damageImages.filter(item => e !== item.id);
        setDamageImages(filteredFiles);
        return true;
      });
    }
  };

  let reqImg = false;

  const handleClose = () => {
    const into10 = 0;
    const stats = { start: into10, end: into10 + 10 };
    dispatch(getAllClaim(stats));
    history.push('/app/allclaim');
  };

  const onError = e => {
    e.target.src = fallback;
  };
  const handleHide = () => {
    setShow(false);
    setoneImage(false);
    setShowRules(true);
    setCount(0);
  };

  const handleHideOneImage = () => setoneImage(false);
  //method to handle the case of the <back button renderPopup modal count===0  going back 
  //to the screen previous to it in the old flow, instead going back to  previous  view
  //in the new flow
  const returnToTips = () => {
    history.push(`/selectRoomType`);
  }

  const handleGoBack = () => {
    const alert = window.confirm('Do you want to go back?');
    if (alert) history.goBack();
  };

  const toggleAutoAddressFormView = () => {
    changeAutoManualAddressFormView(!showManualAddressForm);
  }






  //COUNT === STARTS HERE //COUNT === STARTS  HERE //COUNT === STARTS  HERE
  //COUNT === STARTS HERE //COUNT === STARTS  HERE //COUNT === STARTS  HERE
  //COUNT === STARTS HERE //COUNT === STARTS  HERE //COUNT === STARTS  HERE
  //COUNT === STARTS HERE //COUNT === STARTS  HERE //COUNT === STARTS  HERE
  const renderPopup = () => {
    //RETURN BAAAAAACK FROM NEW UI!!!! RETURN BAAAAAACK FROM NEW UI!!!!RETURN BAAAAAACK FROM NEW UI!!!!
    //RETURN BAAAAAACK FROM NEW UI!!!! RETURN BAAAAAACK FROM NEW UI!!!!RETURN BAAAAAACK FROM NEW UI!!!!
    //RETURN BAAAAAACK FROM NEW UI!!!! RETURN BAAAAAACK FROM NEW UI!!!!RETURN BAAAAAACK FROM NEW UI!!!!
    if (count === 0) {
      return (
        <div>
          <div className="closeImage-top" role="none" onClick={() => handleHide()}>
            <IoIosClose size={30} color="#316FD0" />
          </div>
          {/* <div className="next-position">
            <a
              role="none"
              className="back-link-oneImage"
              onClick={() => {
                returnToTips();
              }}
            >
              {'<'} Back
            </a>
          </div> */}
          <div className="uploadImageTitle">
            Please photograph each wall of the room, moving in a clockwise direction
          </div>
          <div className=" uploadImageSubTitle">
            <p>
              Stand as far back from the wall as you can to make sure the ceiling, floor, and both
              corners are in each picture. Use the wide angle camera feature if you can.
            </p>
          </div>

          <div className="oneImage-bg ImageUploadBg">
            {instruction == true ? (
              <div>
                <p className="instruction-p">
                  {' '}
                  Use Landscape Mode If Possible. If The Room Is Too Narrow, Use Portrait Mode.{' '}
                </p>{' '}
                <div className="two-img">
                  <img src={instruction1} className="instruction-img1" />
                  <img src={instruction2} className="instruction-img2" />
                </div>{' '}
              </div>
            ) : null
            }
            <div className="imageUploadDiv">
              {oppsiteImages?.map((currImage, index) => (
                <ImageAdd
                  key={index}
                  index={index}
                  currImg={currImage}
                  images={oppsiteImages}
                  setImages={setOppositeImages}
                  isWall={true}
                />
              ))}
            </div>
          </div>
          <span>&nbsp;</span>
          <div className='dotsDiv'>
            <p className='activeDot'></p>
            <p className='dot'></p>
            <p className='dot'></p>
            <p className='dot'></p>
          </div>
          <div className='uploadImageSubTitle' >
            <p>Got Q's?&nbsp;</p>
            <p className='p-cursor underline-text' style={{ color: "#6a9eea" }} onClick={() => { setCount(4) }}>See our tips here</p>
          </div>
          <div className='centerDiv'>
            <button
              title="Next"
              className="imageUploadButton"
              onClick={() => {
                handleRoomId();
                if (oppsiteImages?.includes(null)) {
                  setInfo(true)
                }
                else {
                  let valid = true;
                  oppsiteImages.map(cur => {
                    const parts = cur.name.split('.').pop();
                    if (parts === 'jpeg' || parts === 'jpg' || parts === 'png' || parts === 'heic') {
                      valid = true;
                    } else { valid = false }
                  });
                  if (valid === false) {
                    alert(' image type is not supported. We support JPEG, PNG and HEIC');
                  } else {
                    setCount(count + 1);
                    setShow(false);
                  }
                }
              }}
            >
              <p className="next-Txt">Next</p>
            </button>
            {info ?
              <>
                <IoTriangle className='tostArrow1' />
                <div className='tostMessageDiv1'>
                  <IoClose style={{ float: "right", marginLeft: 5 }} onClick={() => setInfo(false)} />
                  <p>Please submit 4 photos to continue</p>
                </div>
              </>
              : null
            }
          </div>
        </div>
      );
    }
    if (count === 1) {
      return (
        <div>
          <div className="closeImage-top" role="none" onClick={() => handleHide()}>
            <IoIosClose size={30} color="#316FD0" />
          </div>
          {/* <div className="next-position">
            <a role="none" className="back-link-oneImage" onClick={() => setCount(count - 1)}>
              {'<'} Back
            </a>
          </div> */}
          <div className=" uploadImageTitle">
            How does it look?
          </div>
          <div className=" uploadImageSubTitle">
            <p>Please confirm each photo contains a full wall</p>
          </div>

          <div className="oneImage-bg ImageUploadBg">
            <div className="imageShowDiv">
              {oppsiteImages?.map((currImage, index) => (
                <ImageAdd
                  key={index}
                  index={index}
                  currImg={currImage}
                  images={oppsiteImages}
                  setImages={setOppositeImages}
                  isWall={true}
                  isImagesError={oppsiteImagesError[index]}
                  isUploadingImages={oppsiteImagesUploading}
                />
              ))}
            </div>
          </div>
          <span>&nbsp;</span>
          <span>&nbsp;</span>
          <div className='dotsDiv'>
            <p className='dot'></p>
            <p className='activeDot'></p>
            <p className='dot'></p>
            <p className='dot'></p>
          </div>
          <div className='centerDiv'>
            <button
              title="Looks good!"
              className="imageUploadButton"
              disabled={oppsiteImages.length < 4}
              onClick={() => {
                let valid = true;
                oppsiteImages.map(cur => {
                  const parts = cur.name.split('.').pop();
                  if (parts === 'jpeg' || parts === 'jpg' || parts === 'png' || parts === 'heic') {
                    valid = true;
                  } else { valid = false }
                });
                if (valid === false) {
                  alert('image type is not supported. We support JPEG, PNG and HEIC');
                } else {
                  if (overlayimagessubbmited == false) {
                    handleOverlayFirst();
                  }
                }
              }}
            >
              <p className="next-Txt">Looks good!</p>
            </button>
          </div>
          <div className='centerDiv'>
            <button
              className="imageUploadSecondaryButton"
            >
              <p className="next-Txt" onClick={() => setCount(count - 1)}>Back</p>
            </button>
          </div>
        </div>
      );
    }
    if (count === 2) {
      return (
        <div>
          <div className="closeImage-top" role="none" onClick={() => handleHide()}>
            <IoIosClose size={30} color="#316FD0" />
          </div>
          <div className=" uploadImageTitle">
            Time to fill in the rest of the room!
          </div>
          <div className=" uploadImageSubTitle">
            <p>
              Try and capture any areas of the room that were not visible in the pictures you took
              earlier
            </p>
          </div>
          <div className="oneImage-bg ImageUploadBg">
            <div className="imageUploadDiv">
              {files?.map((currFile, index) => (
                <ImageAdd
                  key={index}
                  index={index}
                  currImg={currFile}
                  images={files}
                  setImages={setFiles}
                  isTrash={true}
                  isImagesError={filesError[index]}
                  isUploadingImages={filesUploading}
                />
              ))}
            </div>
          </div>
          <span>&nbsp;</span>
          <p className="CenterText">If a wall is too long, take two photos that overlap</p>
          <span>&nbsp;</span>
          <div className="uploadImageTitle">
            Pictures you took earlier
          </div>
          <div className="imageUploadDiv">
            {oppsiteImages?.map((currFile, index) => (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <img
                  className='uploadedimg uploadedimage'
                  src={currFile?.preview}
                />
                <p className='CenterText'>Wall&nbsp;{index + 1}</p>
              </div>
            ))}
          </div>
          <span>&nbsp;</span>
          <div className='dotsDiv'>
            <p className='dot'></p>
            <p className='dot'></p>
            <p className='activeDot'></p>
            <p className='dot'></p>
          </div>
          <div className='centerDiv'>
            <button
              title={files?.some(el => el !== null) === true ? 'Submit Room' : 'Skip this step'}
              className="imageUploadButton"
              onClick={async () => {
                let valid = true;
                let type = '';
                files?.map(cur => {
                  if (cur !== null) {
                    const parts = cur.name.split('.').pop();
                    if (parts === 'jpeg' || 'jpg' || 'png' || 'heic') {
                      valid = true;
                    } else { valid = false };
                  }
                });
                if (valid === false) {
                  alert(type + 'image type is not supported. We support JPEG, PNG and HEIC');
                } else {
                  if (imagessubbmited == false) {
                    handleImagesFirst();
                  }
                  setShow(false);
                }
              }}
            >
              <p className="next-Txt">{files?.some(el => el !== null) === true ? 'Submit Room' : 'Skip this step'}</p>
            </button>
          </div>
        </div>
      );
    }
    if (count === 3) {
      return (
        <div>
          <div className="closeImage-top" role="none" onClick={() => handleHide()}>
            <IoIosClose size={30} color="#316FD0" />
          </div>
          <div>
            <p className="title-one-img">
              Last but not least, capture a{' '}
              <span className="bold-text">close-up of the damage -</span> include{' '}
              <span className="bold-text">floor & ceiling</span> in the shot
            </p>
          </div>

          <div className="oneImage-bg ImageUploadBg">
            <img className="f434" src={img1} />
            <div className="imgList">
              {damageImages.map(file => (
                <div key={file.id}>
                  <div className="img" key={file.name}>
                    <IoIosClose
                      size={50}
                      color="#red"
                      className="closeImgIcon"
                      onClick={() => handleImgDelete(file.id)}
                    />
                    <img
                      src={file.preview}
                      className="uploadedimg"
                      id={file.id}
                      alt="upload"
                      onError={e => onError(e)}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />

              <div>
                {damageImages.length < 1 ? (
                  <div>
                    <PrimaryButton title="UPLOAD IMAGE" className="btn-upload-img" />
                  </div>
                ) : (
                  <div />
                )}
              </div>
            </div>
          </div>
          <div className="next-position">
            <a role="none" className="back-link-oneImage" onClick={() => setCount(count - 1)}>
              Back
            </a>
            <button
              className="next-twoimg new-btn-next"
              disabled={oppsiteImages.length < 1}
              onClick={async () => {
                let valid = true;
                let type = '';
                await oppsiteImages.map(cur => {
                  var parts = cur.path.split('.');
                  type = parts[parts.length - 1];
                  if (type === 'webp' || type === 'gif' || type === 'tiff') {
                    valid = false;
                  }
                });
                if (valid === false) {
                  alert(type + ' image type is not supported. We support JPEG, PNG and HEIC');
                } else {
                  handleFormSubmit();
                  setShow(false);
                }
              }}
            >
              <img className={`next-icon`} src={NextIcon} />
              <p className="next-Txt">Finish</p>
            </button>
          </div>
        </div>
      );
    }
    if (count === 4) {
      return (
        <div >
          <div className="closeImage-top" role="none" onClick={() => setCount(0)}>
            <IoIosClose size={30} color="#316FD0" />
          </div>
          <div className=" uploadImageTitle uploadImageTitleTip">
            Tip 1
          </div>
          <div className=" uploadImageSubTitle">
            <p>
              Pick one wall to start with. Stand back to get a photo of each wall and the edges
            </p>
          </div>
          <div className="oneImage-bg ImageUploadBg">
            <div>
              <div className="two-img">
                <img src={Tip1} className="tip-image1" />
              </div>{' '}
            </div>
          </div>

          <div className=" uploadImageTitle uploadImageTitleTip">
            Tip 2
          </div>
          <div className=" uploadImageSubTitle">
            <p>
              Make sure the ceiling, the floor, and all four corners are in the photo
            </p>
          </div>
          <div className="oneImage-bg ImageUploadBgTip">
            <div>
              <div className="two-tip-img">
                <div className='tipdivwithsign'>
                  <img src={Tip2Wrong} className="tip-image2" />
                  <img src={TipCross} className="tipStatus-image" />
                </div>
                <div className='tipdivwithsign'>
                  <img src={Tip2Right} className="tip-image2" />
                  <img src={TipTick} className="tipStatus-image" />
                </div>
              </div>{' '}
            </div>
          </div>

          <div className=" uploadImageTitle uploadImageTitleTip">
            Tip 3
          </div>
          <div className=" uploadImageSubTitle">
            <p>
              Hold the camera straight on and avoid blurry shots
            </p>
          </div>
          <div className="oneImage-bg ImageUploadBgTip">
            <div>
              <div className="two-tip-img">
                <div className='tipdivwithsign'>
                  <img src={Tip3Wrong} className="tip-image2" />
                  <img src={TipCross} className="tipStatus-image" />
                </div>
                <div className='tipdivwithsign'>
                  <img src={Tip3Right} className="tip-image2" />
                  <img src={TipTick} className="tipStatus-image" />
                </div>
              </div>{' '}
            </div>
          </div>

          <div className=" uploadImageTitle uploadImageTitleTip">
            Tip 4
          </div>
          <div className=" uploadImageSubTitle">
            <p>
              <b></b> Capture walls in a clockwise direction, moving from left to right
            </p>
          </div>
          <div className="oneImage-bg ImageUploadBgTip">
            <div>
              <div className="two-img">
                <img src={Tip4} className="tip-image1" />
              </div>{' '}
            </div>
          </div>

          <div className=" uploadImageTitle uploadImageTitleTip">
            Tip 5
          </div>
          <div className=" uploadImageSubTitle">
            <p>
              If you have an open concept kitchen/dining room, you can picture imaginary walls separating the kitchen and dining room space.
              <br />
              <br />
              Then, take pictures of the space as if it were two rooms
            </p>
          </div>

          <div className='centerDiv'>
            <button
              title="Next"
              className="imageUploadButton"
              onClick={async () => {
                setCount(0);
              }}
            >
              <p className="next-Txt">I'm Ready to Start!</p>
            </button>
          </div>
        </div>
      );
    }
    return <div />;
  };
  //COUNT === ENDS  HERE //COUNT === ENDS  HERE //COUNT === ENDS  HERE
  //COUNT === ENDS  HERE //COUNT === ENDS  HERE //COUNT === ENDS  HERE
  //COUNT === ENDS  HERE //COUNT === ENDS  HERE //COUNT === ENDS  HERE
  //COUNT === ENDS  HERE //COUNT === ENDS  HERE //COUNT === ENDS  HERE


  const handleSelect = (userType) => {
    history.push(`/app/reqimgpopup/${open.claimId}/${userType}`)
  }

  const onClose = () => setOpen(null);


  //RETURN STATEMENT STARTS HERE //RETURN STATEMENT STARTS HERE //RETURN STATEMENT STARTS HERE
  //RETURN STATEMENT STARTS HERE //RETURN STATEMENT STARTS HERE //RETURN STATEMENT STARTS HERE
  //RETURN STATEMENT STARTS HERE //RETURN STATEMENT STARTS HERE //RETURN STATEMENT STARTS HERE
  //RETURN STATEMENT STARTS HERE //RETURN STATEMENT STARTS HERE //RETURN STATEMENT STARTS HERE

  //sessionStorage.oneImagefromSession TRUE forces Count=== modal  toDisplay Line1167
  //sessionStorage.oneImagefromSession TRUE forces  display null Line953

  if (Boolean(open))
    return <UserType open={Boolean(open)} onClose={onClose} onSelect={handleSelect} />

  return (
    <div id="new-claim" className="new-claim">
      <div className="bgImg" />
      <Loader loading={loading} absolute overlay />
      {/* SHOW TRUE OR ONE IMAGE TRUE SUPRESS ENTER PROPERTY ADDRESS
  SHOW TRUE OR ONE IMAGE TRUE SUPRESS ENTER PROPERTY ADDRESS
  SHOW TRUE OR ONE IMAGE TRUE SUPRESS ENTER PROPERTY ADDRESS
  SHOW TRUE OR ONE IMAGE TRUE SUPRESS ENTER PROPERTY ADDRESS
 */}
      {show || oneImage || sessionStorage.oneImagefromSession ? null : (
        <div className="form-newclaim">
          <div className="closeImage" role="none" onClick={handleClose}>
            <IoIosClose size={30} color="#316FD0" />
          </div>



          {/* GOOGLE AUTOCOMPLETE ADDRESS FORM  GOOGLE AUTOCOMPLETE ADDRESS FORM GOOGLE AUTOCOMPLETE ADDRESS FORM */}
          {/* GOOGLE AUTOCOMPLETE ADDRESS FORM  GOOGLE AUTOCOMPLETE ADDRESS FORM GOOGLE AUTOCOMPLETE ADDRESS FORM */}
          {/* GOOGLE AUTOCOMPLETE ADDRESS FORM  GOOGLE AUTOCOMPLETE ADDRESS FORM GOOGLE AUTOCOMPLETE ADDRESS FORM */}
          {/* GOOGLE AUTOCOMPLETE ADDRESS FORM  GOOGLE AUTOCOMPLETE ADDRESS FORM GOOGLE AUTOCOMPLETE ADDRESS FORM */}
          {!showManualAddressForm && (
            <div className="main_form_div">
              <label htmlFor="enterPropertyName" className="heading-labels">
                Enter Property Address
              </label>
              <div className="propertyDamageDiv">
                <div className="input-box-Div">
                  <label className="input-labels">Claim ID</label>
                  <input
                    type=""
                    className="input-text2"
                    placeholder="Type your Claim ID Here"
                    value={customerID}
                    onChange={e => setCustomerID(e.target.value)}
                  />
                </div>
              </div>
              {error.customerID ? <div className="error error-left">{error.customerID} </div> : null}
              <div className="propertyDamageDiv">
                <div className="input-box-Div">
                  <label htmlFor="propertyName" className="input-labels">
                    Enter Address
                  </label>
                  <GooglePlacesAutocomplete
                    //apiKey="AIzaSyB1szvWwPGJsHyofc0KvcaFrX1xEmgQJBw"
                    fetchDetails
                    apiKey="AIzaSyCIecASih5FGMWfSrF-1a_HJIlTqagmaPg"
                    className="input-text"
                    minLengthAutocomplete="3"
                    selectProps={{
                      placeholder: 'Type your address here',
                      onChange: (result, detalis) => {
                        geocodeByPlaceId(result.value.place_id)
                          .then(results => {
                            checkAdress(results, result);
                            //setAddressDetails setAddressDetails setAddressDetails 
                            //setAddressDetails setAddressDetails setAddressDetails 
                            //setAddressDetails setAddressDetails setAddressDetails 
                            setAddressDetails(results);
                          })
                          .catch(e => console.error(e));
                        //setAddress setAddress setAddress setAddress setAddress
                        //setAddress setAddress setAddress setAddress setAddress
                        //setAddress setAddress setAddress setAddress setAddress
                        handleGoogleAddress(result);
                      },
                    }}
                  />
                </div>
                {error.address ? <div className="error error-left">{error.address} </div> : null}
                <div className="propertyDamageDiv">
                  <div className="input-box-Div">
                    <label htmlFor="apt" className="input-labels">
                      Apt/Suite (optional)
                    </label>
                    <input
                      type=""
                      className="input-text1"
                      id="apt"
                      value={apt}
                      onChange={e => setApt(e.target.value)}
                    />
                  </div>
                  {/* {error.apt ? <div className="error error-left">{error.api} </div> : null} */}
                </div>
                {!address ? (
                  <div role="none" className="enteraddLink">
                    <a className='btn_txt' style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={toggleAutoAddressFormView}>Enter Address Manually</a>
                  </div>
                ) : null}
              </div>
              <div className="btn_txt">
                <div className="both_button">
                  <PrimaryButton
                    // disabled={credits < 1}
                    className="new_claims_btn_1"
                    title="ADD ROOM"
                    onClick={handleAddRoom}
                  />
                  {credits < 1 ? (
                    <div className="btn_hover_text1">
                      <p>Additional credits required. Contact info@hostalabs.com</p>
                    </div>
                  ) : null}
                  <PrimaryButton
                    className="new_claims_btn_2"
                    title="REQUEST IMAGE"
                    onClick={handleImgReq}
                  />
                  {/* <div className="btn_hover_text">
                  <p>This will send a web link to customer to upload their own images</p>
                </div> */}
                </div>
              </div>
            </div>
          )}


          {/*  MANUAL ADDRESS FORM MANUAL ADDRESS FORM MANUAL ADDRESS FORM MANUAL ADDRESS FORM MANUAL ADDRESS FORM */}
          {/*  MANUAL ADDRESS FORM MANUAL ADDRESS FORM MANUAL ADDRESS FORM MANUAL ADDRESS FORM MANUAL ADDRESS FORM */}
          {/*  MANUAL ADDRESS FORM MANUAL ADDRESS FORM MANUAL ADDRESS FORM MANUAL ADDRESS FORM MANUAL ADDRESS FORM */}
          {/*  MANUAL ADDRESS FORM MANUAL ADDRESS FORM MANUAL ADDRESS FORM MANUAL ADDRESS FORM MANUAL ADDRESS FORM */}
          {showManualAddressForm && (
            <div>
              <div className="main_form_div">
                <label htmlFor="enterPropertyName" className="heading-labels-manual">
                  Enter Property Address
                </label>
                <div className="both_form">
                  <div className="left_form">
                    <div className="completeaddress">
                      <div className="input-box-Div ">
                        <label htmlFor="claiminfo" className="input-labels">
                          Claim ID
                        </label>
                        <input
                          type="text"
                          className="input-text"
                          placeholder="Type your Claim ID Here"
                          id="address1_manual"
                          value={customerID_manual}
                          onChange={e => setCustomerID_manual(e.target.value)}
                        />
                      </div>
                      {error.customerID ? <div className="error">{error.customerID} </div> : null}
                      <div className="input-box-Div">
                        <label htmlFor="address1" className="input-labels">
                          Street Address
                        </label>
                        <input
                          size="25"
                          type="text"
                          placeholder="123 Highland Street"
                          className="input-text"
                          id="address1"
                          // ADDRESS ONE  ADDRESS ONE  ADDRESS ONE ADDRESS ONE ADDRESS ONE
                          // ADDRESS ONE  ADDRESS ONE  ADDRESS ONE ADDRESS ONE ADDRESS ONE
                          // ADDRESS ONE  ADDRESS ONE  ADDRESS ONE ADDRESS ONE ADDRESS ONE
                          value={address1_manual}
                          onChange={e => setAddress1_manual(e.target.value)}
                        />
                      </div>
                      {error.addressLine1 ? <div className="error">{error.addressLine1} </div> : null}

                      <div className="input-box-Div ">
                        <label htmlFor="address2" className="input-labels">
                          Apt/Suite (optional)
                        </label>
                        <input
                          size="25"
                          type="text"
                          placeholder="1"
                          className="input-text"
                          id="address2"
                          // ADDRESS TWO  ADDRESS TWO ADDRESS TWO ADDRESS TWO ADDRESS TWO 
                          // ADDRESS TWO  ADDRESS TWO ADDRESS TWO ADDRESS TWO ADDRESS TWO 
                          // ADDRESS TWO  ADDRESS TWO ADDRESS TWO ADDRESS TWO ADDRESS TWO 
                          value={address2}
                          onChange={e => setAddress2(e.target.value)}
                        />
                      </div>
                      <div className="input-box-Div ">

                        <div className="input-box-Div">
                          <label htmlFor="city" className="input-labels">
                            City/State/ZIP
                          </label>
                          <input
                            size="25"
                            type="text"
                            placeholder="Sacramento"
                            className="input-text"
                            id="city"
                            // CITY CITY CITY  CITY CITY CITY CITY CITY CITY
                            // CITY CITY CITY  CITY CITY CITY CITY CITY CITY
                            // CITY CITY CITY  CITY CITY CITY CITY CITY CITY
                            value={city_manual}
                            onChange={e => {
                              if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                setCity_manual(e.target.value);
                              }
                            }}
                          />
                        </div>
                        {error.city ? <div className="error">{error.city} </div> : null}
                        {/* STATE STATE STATE STATE STATE STATES STATE STATE STATE  */}
                        {/* STATE STATE STATE STATE STATE STATES STATE STATE STATE */}
                        {/* STATE STATE STATE STATE STATE STATES STATE STATE STATE */}
                        <div className="cityCodeinner">
                          <div className="cityCode">
                            <select
                              className="selectcity"
                              value={usState_manual}
                              onChange={e => setUSstate_manual(e.target.value)}
                            >
                              {usaStates.map(usStateVal => (
                                <option key={usStateVal} value={usStateVal}>{usStateVal}</option>
                              ))}
                            </select>
                            {error.cityCode ? <div className="error">{error.cityCode} </div> : null}
                          </div>
                          {/* ZIP ZIP ZIP */}
                          {/* ZIP ZIP ZIP */}
                          {/* ZIP ZIP ZIP */}
                          <div className="countryCodeDiv">
                            <input
                              size="13"
                              type="number"
                              placeholder="02145"
                              className="input-text new-input hide_number_icon"
                              id="countryCode"
                              value={zip_manual}
                              onChange={e => setZip_manual(e.target.value)}
                            />
                            {error.zip ? <div className="error">{error.zip} </div> : null}
                          </div>
                        </div>
                      </div>
                      {/* CLAIMID CLAIMID CLAIMID */}
                      {/* CLAIMID CLAIMID CLAIMID */}
                      {/* CLAIMID CLAIMID CLAIMID */}
                    </div>
                  </div>
                  <div className="right_form">
                    <PrimaryButton
                      className="new_claims_btn_b"
                      title="REQUEST IMAGE"
                      onClick={() => {
                        //Calls handleImageRequest
                        //Calls handleImageRequest
                        //Calls handleImageRequest
                        manualAddressHandleImageRequest();
                      }}
                    />
                    <PrimaryButton
                      disabled={credits < 1}
                      className="new_claims_btn_b new_claims_btn"
                      title="ADD ROOM"
                      onClick={() => {
                        manualAddressHandleChecks();
                      }}
                    />
                    {credits < 1 ? (
                      <div className="btn_hover_text">
                        <p>Additional credits required. Contact info@hostalabs.com</p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="go_back" role="none" onClick={handleGoBack}>
                <IoIosArrowBack color="#537BB8" />
                <p className="m-0"> Back</p>
              </div>
            </div>
          )}
        </div>
      )}




      {/* END OF MANUAL ADDRESS FORM MANUAL ADDRESS FORM MANUAL ADDRESS FORM */}
      {/* END OF MANUAL ADDRESS FORM MANUAL ADDRESS FORM MANUAL ADDRESS FORM */}
      {/* END OF MANUAL ADDRESS FORM MANUAL ADDRESS FORM MANUAL ADDRESS FORM */}
      {/* END OF MANUAL ADDRESS FORM MANUAL ADDRESS FORM MANUAL ADDRESS FORM */}







      <Modal show={modalShown} centered animation size="md" className="shadow-lg roomps">
        <Modal.Body className="text-center p-5">
          <h3 className="m-0">Property Added Successfully</h3>
        </Modal.Body>
      </Modal>
      <Modal
        show={oneImage || sessionStorage.oneImagefromSession}
        onHide={handleHideOneImage}
        animation
        size="lg"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        className="shadow-lg fullModelCss"
      >
        <Modal.Body>
          {count <= 4 ?
            <div>
              <div className='HostaAILogoDiv'>
                <img src={HostaAILogo} />
              </div>
              {renderPopup()}
            </div>
            : handleClose()}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NewClaim;