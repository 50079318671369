import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col, Table, Pagination, Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { BiX } from 'react-icons/bi';
import { MdSort } from 'react-icons/md';
import Loader from '../../../components/Loader/Loader';
import PrimaryButton from '../../../components/Buttons/PrimaryButton/PrimaryButton';
import Breadcrumb from './Layout/Breadcrumb/Breadcrumb';
import { getAllRoom, deleteRoom } from '../../../store/actions/allRoom';
import { downloadReport, downloadDrawings } from '../../../store/actions/downloads';
import CustomImage from '../../../components/CustomImage/CustomImage';
import { setActiveRoomPage } from '../../../store/actions/pagination';
import { getClaim, getRoomData } from '../../../store/actions/roomInterface';
import { getAllClaim } from '../../../store/actions/allClaim';
import { setActiveClaimPage } from '../../../store/actions/pagination';
import NewPortalContext from '../../../new-portal-context/NewPortalContext';
import axios from '../../../store/axios'
import MuiPagination from '@material-ui/lab/Pagination';
import UserType from '../RoomInterface/Layouts/UserType/UserType';

const isDemo = Boolean(process.env.REACT_APP_NEURO_API_URL);

const AllRoom = () => {
  const { claimId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const activePagination = useSelector(state => state.pagination.activeRoomPage);
  const credits = useSelector(state => state.credit.credits);
  const allClaimData = useSelector(state => state.claims.allClaims);
  const totalRooms = useSelector(state => state.claims.totalRooms);
  const [claimData, setClaimData] = useState(allClaimData.find(cur => cur.claimId === claimId));
  const [isRendered, setRendered] = useState(false);
  const allRoomInitialData = useSelector(
    state => state.claims.allClaims.find(cur => cur.claimId === claimId)?.rooms || []
  );
  const [allRoomData, setRoomData] = useState([...allRoomInitialData]);
  const [allRoomActiveClasses, setAllRoomActiveClasses] = useState(allRoomData.map(item => false));
  const [modalShown, setModalShown] = useState(false);
  const [modalErrorText, setModalErrorText] = useState('');
  const [removeRoomId, setRemoveRoomId] = useState('');
  const [types, setTypes] = useState({});
  const [lastIndex, setLastIndex] = useState(10);
  const [loading, setLoading] = useState(false);
  const [showModalBtns, setShowModalBtns] = useState(true);

  const [roomstartd, setRoomstartd] = useState(0);
  const [roomendd, seteRoomendd] = useState(10);

  const { updateReturnUrlPath } = useContext(NewPortalContext);
  const { updateOneImageFromContext } = useContext(NewPortalContext);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (totalRooms % 10 === 0) setLastIndex(Math.floor(Number(totalRooms / 10)));
    else setLastIndex(Math.floor(Number(totalRooms / 10)) + 1);
    if (allRoomInitialData.length > 0) setRoomData([...allRoomInitialData]);
  }, [allRoomInitialData]);

  useEffect(() => {
    initialData(0, 10);
  }, [claimData, claimId, dispatch]);

  const initialData = async (start, end, refresh) => {
    if (!claimData || !claimData.rooms || refresh) {
      setLoading(true);
      !refresh && dispatch(setActiveRoomPage(1));
      const res1 = await dispatch(getClaim(claimId));
      let data = await dispatch(getAllRoom(claimId, { start, end }));
      setLoading(false);
      setRoomData(data);
      setClaimData({ ...res1, rooms: data });
    }
    setRendered(true);
  };

  const GoToInterface = index => {
    history.push(`/app/interface/${claimId}/${claimData.rooms[index].roomId}/`);
  };
  const GoToProcessinInterface = index => {
    history.push(`/app/processingInterface/${claimId}/${claimData.rooms[index].roomId}/`);
  };
  const ShowRemovePopup = (keyIndex, roomId) => {
    allRoomActiveClasses[keyIndex] = true;
    setRemoveRoomId(roomId);
    setModalErrorText('Are you sure you want to remove?');
    setModalShown(true);
    setShowModalBtns(true);
  };

  const RemoveClaim = async () => {
    setLoading(true);
    let res = await dispatch(deleteRoom(claimId, removeRoomId));
    setLoading(false);
    if (!res) {
      setModalErrorText('Error: cannot delete while a room is processing');
      setShowModalBtns(false);
    } else setModalShown(false);
  };

  const ShowInfoPopup = keyIndex => {
    allRoomActiveClasses[keyIndex] = true;
    setRemoveRoomId('');
    setModalErrorText('Your room images are under process.');
    setModalShown(true);
    setShowModalBtns(true);
  };

  const hideModel = () => {
    setAllRoomActiveClasses(allRoomData.map(item => false));
    setModalShown(false);
  };

  const handlePagination = async page => {
    if (activePagination !== page) {
      const into10 = (page - 1) * 10;
      const stats = { start: into10, end: into10 + 10 };
      console.log(into10);
      console.log(stats);
      setRoomstartd(into10);
      seteRoomendd(into10 + 10);
      initialData(into10, into10 + 10, true);
      dispatch(setActiveRoomPage(page));
    }
  };

  const handleSort = async type => {
    let newData = [...allRoomData];
    if (types[type]) {
      if (type === 'room') {
        newData = newData.sort((a, b) =>
          a.roomName !== b.roomName ? (a.roomName < b.roomName ? -1 : 1) : 0
        );
        setRoomData(newData);
      }
      if (type === 'date') {
        newData = newData.sort(
          (a, b) => new Date(b.lastModified * 1000) - new Date(a.lastModified * 1000)
        );
        setRoomData(newData);
      }
      if (type === 'status') {
        newData = newData.sort((a, b) => (b.status <= a.status ? 1 : -1));
        setRoomData(newData);
      }
      if (type === 'type') {
        newData = newData.sort((a, b) => (b.roomType <= a.roomType ? 1 : -1));
        setRoomData(newData);
      }
      setTypes({ ...types, [type]: false });
      return;
    }
    if (type === 'room') {
      newData = newData.sort((a, b) =>
        b.roomName !== a.roomName ? (b.roomName < a.roomName ? -1 : 1) : 0
      );
      setRoomData(newData);
    }
    if (type === 'date') {
      newData = newData.sort(
        (a, b) => new Date(a.lastModified * 1000) - new Date(b.lastModified * 1000)
      );
      setRoomData(newData);
    }
    if (type === 'status') {
      newData = newData.sort((a, b) => (a.status <= b.status ? 1 : -1));
      setRoomData(newData);
    }
    if (type === 'type') {
      newData = newData.sort((a, b) => (a.roomType <= b.roomType ? 1 : -1));
      setRoomData(newData);
    }
    setTypes({ ...types, [type]: true });
  };

  const downloadReportFn = async () => {
    setLoading(true);
    const reportRes = await dispatch(downloadReport(claimId));
    setLoading(false);
  };
  const downloadDrawingsFn = async () => {
    setLoading(true);
    const drawingRes = await dispatch(downloadDrawings(claimId));
    setLoading(false);
  };

  if (!isRendered) return <Loader type="half" />;
  console.log(allRoomData);
  const nonProcessedRooms = allRoomData.filter(item => item.status !== 'Processed');

  let disableBtns = false;
  if (nonProcessedRooms.length > 0) disableBtns = true;


  const bridgeToNewUi = () => {
    //NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE : 
    //NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE : 
    //No preservedClaimId of any kind needs to be set here. NewRoom gets claimId 
    //from the parameter
    //context needs claimId as as so that roomId can be created in new UI
    //roomId will be consumed in NewRoom on return to Latitude UI handleRoomId
    //sessionStorage.preservedTempClaimId = claimId;
    //updateReturnUrlPath(`/app/newRoom/${claimId}`);
    sessionStorage.returnUrlPath = `/app/newRoom/${claimId}`;
    history.push(`/startme/param`);
  }

  const addNewRoom = () => {
    axios({
      method: 'POST',
      url: `/claim/${claimId}/room/request?returnLink=True&expirationHours=2160`,
      data: {
        // claimInfo: data,
        // customerID: customerID
      },
    }).then(res => {
      console.log(res.data.link)
      window.location.href = res.data.link + '/' + claimId
    })

  }

  const handleReqImage = () => {
    setOpen(true);
  }

  const handleSelect = (value) => {
    setOpen(false);
    history.push(`/app/reqimgpopup/${claimData.claimId}/${value}`)
  }
  
  const handleUploadImages = () => {
    history.push(`/app/room/${claimData.claimId}/upload-images`);
  };

  return (
    <Fragment>
      {claimData.claimInfo.customerID !== '' && claimData.claimInfo.customerID !== undefined && (
        <p className="claimIDHeader">Claim ID: {claimData.claimInfo.customerID}</p>
      )}
      <Loader loading={loading} absolute overlay />
      <UserType open={open} onClose={() => setOpen(false)} onSelect={handleSelect} />
      <div id="allroom">
        <Row className="mt-5">
          <Col sm={12} md={12} className="mt-4">
            <Breadcrumb
              step1Onclick={() => {
                //UpdateOneImageFromContext to false so that clicking on "AddProperty"
                //button in NewClaim will display the NewProperty Modal instead of imageUpload modal
                updateOneImageFromContext(false);
                dispatch(setActiveClaimPage(1));
                const stats = { start: 0, end: 10 };
                dispatch(getAllClaim(stats));
                history.push('/app/allclaim');
              }}
              data={{
                name: `${claimData.claimInfo.address2 || ''} ${claimData.claimInfo.address1} ,
                ${claimData.claimInfo.city === claimData.claimInfo.state
                    ? ''
                    : claimData.claimInfo.city
                  } ,
                ${claimData.claimInfo.state}, ${claimData.claimInfo.country}, ${claimData.claimInfo.zip
                  }`,
                id: 'ek7c47d3-68ec-47ca-9b63-0c0b74174f43.png',
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} lg={9} className="mt-1">
            <div id="ClaimsTable">
              <Table responsive className="table-curved" role="grid">
                {allRoomData.length > 0 ? (
                  <thead>
                    <tr>
                      <th onClick={() => handleSort('room')}>
                        <p>Sort {window.innerWidth < 600 ? null : <MdSort size={25} />}</p>
                      </th>
                      <th className="middl-claim" onClick={() => handleSort('date')}>
                        <p>Sort {window.innerWidth < 600 ? null : <MdSort size={25} />}</p>
                      </th>
                      <th onClick={() => handleSort('status')}>
                        <p>Sort {window.innerWidth < 600 ? null : <MdSort size={25} />}</p>
                      </th>
                      <th onClick={() => handleSort('type')}>
                        <p>Sort {window.innerWidth < 600 ? null : <MdSort size={25} />}</p>
                      </th>
                      <th />
                      <th style={{ width: '1%' }} />
                    </tr>
                  </thead>
                ) : null}
                <tbody>
                  {allRoomData.map((room, index) => (
                    <tr key={room.roomId}>
                      <td
                        role="gridcell"
                        className={allRoomActiveClasses[index] ? 'activeTD' : null}
                        onClick={() =>
                          !['Processed'].includes(room.status) ? null : GoToInterface(index)
                        }
                        style={{ verticalAlign: 'middle' }}
                      >
                        <b style={{ textTransform: 'capitalize' }}>{room.roomName}</b>
                      </td>
                      <td
                        className={
                          allRoomActiveClasses[index] ? 'activeTD middl-claim' : 'middl-claim'
                        }
                        role="gridcell"
                        onClick={() =>
                          !['Processed'].includes(room.status) ? null : GoToInterface(index)
                        }
                      >
                        <b>Last Modified</b>
                        <br />
                        <p>{new Date(room.lastModified * 1000).toLocaleString('en-US')}</p>
                      </td>
                      <td
                        className={allRoomActiveClasses[index] ? 'activeTD' : null}
                        role="gridcell"
                        onClick={() =>
                          !['Processed'].includes(room.status) ? null : GoToInterface(index)
                        }
                      >
                        <b>Status</b>
                        <br />
                        {room.status === 'Processed' ? (
                          <p className="greenText">{room.status}</p>
                        ) : (
                          <div>
                            <p>{room.status}</p>
                            <PrimaryButton
                              className="processing-btn"
                              title="Image Preview"
                              onClick={() => {
                                GoToProcessinInterface(index);
                              }}
                            />
                          </div>
                        )}
                      </td>
                      <td
                        className={allRoomActiveClasses[index] ? 'activeTD' : null}
                        role="gridcell"
                        onClick={() =>
                          !['Processed'].includes(room.status) ? null : GoToInterface(index)
                        }
                      >
                        <b>Type</b>
                        <br />
                        <p>{room.roomType}</p>
                      </td>
                      <td
                        className={allRoomActiveClasses[index] ? 'activeTD' : null}
                        role="gridcell"
                        onClick={() =>
                          !['Processed'].includes(room.status) ? null : GoToInterface(index)
                        }
                        style={{ textAlign: 'right' }}
                      >
                        {room.thumbnails.map((image, i) => (
                          <CustomImage key={i} src={image} className="img-responsive" alt="Room" />
                        ))}
                      </td>
                      <td
                        className={allRoomActiveClasses[index] ? 'activeTD' : null}
                        style={{ width: '1%' }}
                      >
                        <BiX size={25} onClick={() => ShowRemovePopup(index, room.roomId)} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            {lastIndex > 1 && <MuiPagination variant="outlined" color='primary' shape="rounded" showFirstButton showLastButton onChange={(evt, page) => handlePagination(page)} count={lastIndex} />}
            {/* {lastIndex > 1 ? (
              <div id="ClaimsPagination">
                <Pagination>
                  <Pagination.First active={false} onClick={() => handlePagination(1)} />
                  <Pagination.Prev
                    active={false}
                    onClick={() => handlePagination(activePagination - 1)}
                  />
                  {[...Array(lastIndex)].map((i, number) => (
                    <Pagination.Item
                      key={`allcalim_paginate_${number + 1}`}
                      onClick={() => handlePagination(number + 1)}
                      active={number + 1 === activePagination}
                    >
                      {number + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    active={false}
                    onClick={() => handlePagination(activePagination + 1)}
                  />
                  <Pagination.Last active={false} onClick={() => handlePagination(lastIndex)} />
                </Pagination>
              </div>
            ) : null} */}
          </Col>
          <Col sm={12} md={12} lg={3} className="fixedRight">
            <div id="btnSection">
              <Col sm={12} md={12} className="mt-4">
                <PrimaryButton
                  className="new_claims_btn"
                  title="REQUEST IMAGE"
                  onClick={handleReqImage}
                />
              </Col>
              <Col sm={12} md={12} className="mt-3">
                <PrimaryButton
                  disabled={credits < 1}
                  className="new_claims_btn"
                  title="ADD ROOM"
                  onClick={addNewRoom}
                />
                {credits < 1 ? (
                  <div className="btn_hover_text">
                    <p>Additional credits required. Contact info@hostalabs.com</p>
                  </div>
                ) : null}
              </Col>
              {isDemo && <Col sm={12} md={12} className="mt-4">
                <PrimaryButton
                  className="new_claims_btn"
                  title="UPLOAD ROOM IMAGES"
                  onClick={handleUploadImages}
                />
              </Col>}
              <Col sm={12} md={12} className="mt-4">
                <PrimaryButton
                  disabled={disableBtns}
                  className="new_claims_btn"
                  title="DOWNLOAD REPORT"
                  onClick={() => downloadReportFn()}
                />
              </Col>
            </div>
          </Col>
        </Row>
      </div>
      <Modal
        show={modalShown}
        onHide={hideModel}
        centered
        animation
        size="md"
        className="shadow-lg roomdd"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" />
        </Modal.Header>
        <Modal.Body className="text-center">
          <h3>{modalErrorText}</h3>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          {showModalBtns ? (
            <div>
              {removeRoomId ? (
                <PrimaryButton
                  className="new_claims_btn float-left mr-3"
                  title="Remove"
                  onClick={() => RemoveClaim()}
                />
              ) : null}
              <PrimaryButton
                className="new_claims_btn"
                title={removeRoomId ? 'Cancel' : 'OK'}
                onClick={hideModel}
              />
            </div>
          ) : null}
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default AllRoom;
